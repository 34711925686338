import React from "react";
import { graphql, Link } from "gatsby";
import dayjs from "dayjs";
import { Layout } from "~/templates/Layout";
import { ArticleCards } from "~/molecules/ArticleCards";
import { EventArticleCards } from "~/molecules/EventArticleCards";
import { ArticleListSection } from "~/molecules/ArticleListSection";
import { PageHeading } from "~/atoms/PageHeading";
import { PATH } from "~/constant/path";
import { CallToActionButton } from "~/atoms/CallToActionButton";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

type Props = {
  data: {
    event: GatsbyTypes.ContentfulEventConnection;
    report: GatsbyTypes.ContentfulReportConnection;
    awardReport: GatsbyTypes.ContentfulReportConnection;
    awards: GatsbyTypes.ContentfulAwardConnection;
  };
};

const DESCRIPTION = [
  "リファラル採用の定着に向けた多数の支援経験をもとに",
  "さらに多くの企業様を成功に導くためのお話をするイベントを開催しています",
];

const EventListPage: React.FC<Props> = ({ data }) => {
  const breadCrumbItems = [PATH.TOP, PATH.EVENTS];

  const eventArtcles = data.event.edges.map((edge) => {
    return {
      node: edge.node,
      href: `/events/${edge.node.eventCategory?.slug}/${edge.node.contentful_id}`,
    };
  });

  const seminarReportArticles = data.report.edges.map((edge) => {
    return {
      node: edge.node,
      href: `/events/${edge.node.eventCategory?.slug}/${edge.node.contentful_id}`,
    };
  });

  return (
    <>
      <style jsx>{`
        .buttonWrapper {
          display: flex;
          justify-content: center;
          margin-top: 30px;
        }
        .buttonWrapper :global(a) {
          padding: 15px 30px;
        }
        .articles {
          display: flex;
          flex-wrap: wrap;
          position: relative;
          width: 100%;
        }
        .article {
          background-color: ${colors.white};
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          margin: 15px;
          transition: box-shadow 0.3s ease;
          width: calc(100% / 3 - 30px);
        }
        .article:hover {
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
        }
        .article:hover .eyecatch {
          opacity: 0.8;
        }
        .eyecatch {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 6px 6px 0 0;
          padding-top: 60%;
          transition-property: background-size, opacity;
          transition-duration: 0.4s;
          transition-timing-function: ease;
          width: 100%;
        }
        .body {
          display: flex;
          flex-direction: column;
          padding: 20px;
        }
        .title {
          color: ${colors.defaultText};
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5;
        }
        .companyName {
          color: ${colors.defaultText};
          font-size: 14px;
          margin: 10px 0;
        }
        .date {
          color: ${colors.littleBrightGray};
          font-size: 15px;
          font-weight: 500;
          margin-top: auto;
        }
        @media (${breakPoints.sp}) {
          .article {
            width: 100%;
            margin: 20px 0;
          }
        }
      `}</style>
      <Layout title={PATH.EVENTS.name} ogpDescription={DESCRIPTION.join("")} breadCrumbItems={breadCrumbItems}>
        <div className="pageWrapper">
          <PageHeading title={PATH.EVENTS.name}>
            {DESCRIPTION.map((str, index) => {
              return (
                <React.Fragment key={`${str}_${index}`}>
                  {DESCRIPTION.length - 1 === index ? (
                    str
                  ) : (
                    <>
                      {str}
                      <br />
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </PageHeading>
          <ArticleListSection title={PATH.EVENTS_SEMINAR.name}>
            <EventArticleCards articles={eventArtcles} />
            <div className="buttonWrapper">
              <CallToActionButton to={PATH.EVENTS_SEMINAR.to} backgroundColor={colors.creamGray} size={`300px`}>
                {PATH.EVENTS_SEMINAR.name}一覧を見る
              </CallToActionButton>
            </div>
          </ArticleListSection>
          <ArticleListSection title={PATH.EVENTS_REPORT.name}>
            <ArticleCards articles={seminarReportArticles} prefix="SeminarReportArticles" />
            <div className="buttonWrapper">
              <CallToActionButton to={PATH.EVENTS_REPORT.to} backgroundColor={colors.creamGray} size={`300px`}>
                {PATH.EVENTS_REPORT.name}一覧を見る
              </CallToActionButton>
            </div>
          </ArticleListSection>
          <ArticleListSection title={PATH.EVENTS_AWARD.name}>
            <div className="">
              <div className="articles">
                {data.awards.edges.map((edge) => {
                  const { node } = edge;
                  return (
                    <article key={`Awards_${node.contentful_id}`} className="article">
                      <Link to={`${PATH.AWARD.to}/${node.contentful_id}`}>
                        <div className="eyecatch" style={{ backgroundImage: `url(${node.eyecatch?.fluid?.src})` }} />
                        <div className="body">
                          <h2 className="title">{node.title}</h2>
                          {node?.company && <div className="companyName">{node.company.name}</div>}
                          <div className="date">{dayjs(node.updatedAt).format("YYYY.MM.DD")}</div>
                        </div>
                      </Link>
                    </article>
                  );
                })}
                {data.awards.totalCount < 3 &&
                  data.awardReport.edges.slice(0, 3 - data.awards.totalCount).map((edge) => {
                    const { node } = edge;

                    return (
                      <article key={`Awards_${node.contentful_id}`} className="article">
                        <Link
                          to={`/events/${node.eventCategory?.slug}/interview${node.awardYear}/${node.contentful_id}`}
                        >
                          <div className="eyecatch" style={{ backgroundImage: `url(${node.ogpImage?.fluid?.src})` }} />
                          <div className="body">
                            <h2 className="title">{node.title}</h2>
                            {node.companyName && <div className="companyName">{node.companyName}</div>}
                            <div className="date">{dayjs(node.date).format("YYYY.MM.DD")}</div>
                          </div>
                        </Link>
                      </article>
                    );
                  })}
              </div>
            </div>
            <div className="buttonWrapper">
              <CallToActionButton to={PATH.EVENTS_AWARD.to} backgroundColor={colors.creamGray} size={`300px`}>
                {PATH.EVENTS_AWARD.name}について
                <br />
                詳しく見る
              </CallToActionButton>
            </div>
          </ArticleListSection>
        </div>
      </Layout>
    </>
  );
};

export default EventListPage;

export const eventListPageQuery = graphql`
  query eventListPage {
    event: allContentfulEvent(limit: 3, sort: { fields: date, order: DESC }) {
      edges {
        node {
          date
          endedAt
          eventCategory {
            slug
          }
          ogpImage {
            fluid(maxWidth: 767) {
              src
              aspectRatio
            }
          }
          location
          title
          contentful_id
          receptionStatus
        }
      }
    }
    report: allContentfulReport(
      filter: { eventCategory: { slug: { eq: "report" } } }
      limit: 3
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          date
          ogpImage {
            fluid(maxWidth: 767) {
              src
              aspectRatio
            }
          }
          title
          contentful_id
          eventCategory {
            slug
          }
        }
      }
    }
    awardReport: allContentfulReport(
      filter: { eventCategory: { slug: { eq: "award" } } }
      limit: 3
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          date
          awardYear
          companyName
          ogpImage {
            fluid(maxWidth: 767) {
              src
              aspectRatio
            }
          }
          title
          contentful_id
          eventCategory {
            slug
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }
    }
    awards: allContentfulAward(limit: 3, sort: { fields: publishedAt, order: DESC }) {
      totalCount
      edges {
        node {
          id
          contentful_id
          eventYear
          eyecatch {
            fluid {
              src
            }
          }
          title
          createdAt(fromNow: false)
          publishedAt(fromNow: false)
          company {
            name
            id
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
`;
