import React from "react";
import { Link } from "gatsby";
import dayjs from "dayjs";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import ThumbsBlankJpg from "~/images/thumbBlank.jpg";

type Article = {
  node: (GatsbyTypes.ContentfulCase & { companyName?: string }) | GatsbyTypes.ContentfulReport;
  href: string;
};

type Props = {
  articles: Article[];
  prefix: string;
};

const ArticleCard: React.FC<Article> = ({ node, href }) => {
  const { ogpImage, title, companyName, date } = node;

  return (
    <>
      <style jsx>{`
        .card {
          background-color: ${colors.white};
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          margin: 15px;
          transition: box-shadow 0.3s ease;
          width: calc(100% / 3 - 30px);
        }
        .card:hover {
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
        }
        .card:hover .cover {
          opacity: 0.8;
        }
        .cover {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url(${ogpImage?.fluid?.src || ThumbsBlankJpg});
          border-radius: 6px 6px 0 0;
          padding-top: 64%;
          transition-property: background-size, opacity;
          transition-duration: 0.4s;
          transition-timing-function: ease;
          width: 100%;
          height: 0;
        }
        .body {
          color: ${colors.defaultText};
          padding: ${date && `20px 30px 10px`};
        }
        .title {
          font-size: 16px;
          font-weight: 500;
          line-height: 26px;
          letter-spacing: 1px;
        }
        .companyName {
          font-size: 14px;
          display: block;
          margin: 5px 0 10px;
        }
        .dateContainer {
          margin-top: auto;
          padding: 0 30px 30px;
          display: flex;
          flex-wrap: wrap;
        }
        .createdAt {
          color: ${colors.littleBrightGray};
          display: block;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0.08rem;
        }
        @media (${breakPoints.sp}) {
          .card {
            width: 100%;
            margin: 15px 0;
          }
        }
      `}</style>
      <li className="card">
        <Link to={href}>
          <div className="cover" />
          <div className="body">
            <h2 className="title">{title}</h2>
            {companyName && <div className="companyName">{companyName}</div>}
          </div>
          {date && (
            <div className="dateContainer">
              {date && <div className="createdAt">{dayjs(date).format("YYYY.MM.DD")}</div>}
            </div>
          )}
        </Link>
      </li>
    </>
  );
};

export const ArticleCards: React.FC<Props> = ({ articles, prefix }) => (
  <>
    <style jsx>{`
      .articleCards {
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
    `}</style>
    <ul className="articleCards">
      {articles.map((article) => (
        <ArticleCard key={`${prefix}_${article.node.contentful_id}`} node={article.node} href={article.href} />
      ))}
    </ul>
  </>
);
